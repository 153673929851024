//@import '@primer/css/core/index.scss';

// Global requirements
@import "@primer/css/support/index.scss";
// Core modules
@import "@primer/css/base/index.scss";
@import "@primer/css/box/index.scss";
@import "@primer/css/breadcrumb/index.scss";
@import "@primer/css/buttons/index.scss";
@import "@primer/css/table-object/index.scss";
@import "@primer/css/layout/index.scss";
@import "@primer/css/links/index.scss";
@import "@primer/css/navigation/index.scss";
@import "@primer/css/pagination/index.scss";
@import "@primer/css/tooltips/index.scss";
@import "@primer/css/truncate/index.scss";
// Utilities always go last so that they can override components
@import "@primer/css/utilities/index.scss";

// Can't import all of forms, because form-validation has references to missing images. 
// Instead, we manually pull in all other components of forms. 
//@import "@primer/css/forms/index.scss";
@import "@primer/css/forms/form-control.scss";
@import "@primer/css/forms/form-select.scss";
@import "@primer/css/forms/form-group.scss";
//@import "@primer/css/forms/form-validation.scss";
@import "@primer/css/forms/input-group.scss";
@import "@primer/css/forms/radio-group.scss";

$myColor: green;

h1 {
  color: $myColor;
}

@include color-variables(
  (
    "border-primary": (
      dark: white
    ),
    "border-secondary": (
      dark: white
    ),
    "border-tertiary": (
      dark: white
    ),
    "border-overlay": (
      dark: white
    ),
    "bg-canvas": (
      dark: black
    ),
    "bg-primary": (
      dark: black
    ),
    "bg-secondary": (
      dark: black
    ),
    "bg-tertiary": (
      dark: black
    ),
    "bg-backdrop": (
      dark: gray
    ),
    "bg-canvas-mobile": (
      dark: black
    ),
    "bg-canvas-inverse": (
      dark: white
    ),
    "bg-canvas-inset": (
      dark: black
    ),
    "bg-overlay": (
      dark: black
    ),


    "text-primary": (
      dark: white
    ),
    "text-secondary": (
      dark: black
    ),
    "text-tertiary": (
      dark: black
    ),
    "text-placeholder": (
      dark: grey
    ),
    "text-disabled": (
      dark: grey
    ),
  )
);