@import '../fonts/tt-norms-pro/style.css';

.machine-list {
  min-width: 50%;
}

.project-list {
  min-width: 70%;
}

.package-list {
  min-width: 50%;
}

.new-project {
  width: 50%;
}

.project-details {
  min-width: 30%;
  max-width: 90%;
}

.project-details {
  width: 95%;
}

.link {
  color: var(--color-text-link);
}

.as-icon, .cloud-upload-icon {
  filter: invert(68%) sepia(8%) saturate(7418%) hue-rotate(185deg) brightness(175%) contrast(102%);;
}

.puzzle-icon {
  filter: invert(68%) sepia(8%) saturate(7418%) hue-rotate(185deg) brightness(175%) contrast(102%);;
}

.global-bg {
  background-color: var(--color-scale-blue-1);
}

.square {
  background-color: var(--color-bg-canvas);
}

* {
  font-family: 'TT Norms Pro Regular', 'Arial';
}

:root {
  --amplify-background-color: transparent;
  --amplify-primary-color: var(--color-border-info);
  --amplify-primary-tint: var(--color-icon-primary);
  --amplify-primary-shade: var(--color-icon-secondary);
  --amplify-primary-contrast: var(--color-bg-canvas);
  --amplify-secondary-color: var(--color-scale-gray-4);
  --amplify-grey: var(--color-text-tertiary);
}

amplify-authenticator {
  --container-height: 400px;
  --width: 600px;
  --container-width: 100px;
  --border-radius: 10px;
  --box-shadow: none;
}

amplify-section {
  background-color: green;
}

.app {
  min-height: 100vh;
}

.icn-flip {
  transform: scaleX(-1);
}

.icn-spinner {
  animation: spin-animation 3s linear infinite;
  display: inline-block;
}

@keyframes spin-animation {
  100% {
    transform: rotate(360deg);
  }
}

.icn-wiggle {
  display: inline-block;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.icn-bounce {
  display: inline-block;
  animation: bounce 1s infinite linear;
}

@keyframes bounce {
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}

.machine-overview {
  min-width: 25%;
}

.status-text {
  width: 40%;
}

.no-multiline {
  white-space: nowrap;
  overflow: hidden;
}

.fill-width {
  width: 100%;
}

.version-status {
  font-size: 1rem;
}

.circle-container {
  height: 17px;
  width: 17px;
  display: inline-block;
  transform: translateX(7px) translateY(-7px);
}

.icon-in-circle {
  transform: translateX(0px) translateY(-3px);
}

.text-in-circle {
  text-align: center;
}

.content-box {
  padding: 10px;
  border-color: var(--color-border-primary);
  border-width: 0px;
  border-style: solid;
  background-color: var(--color-bg-overlay);
  color: var(--color-text-primary);
  border-radius: 10px;
}

#sign-out-button,
#sign-out-text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  --amplify-primary-color: transparent;
}

#sign-out-container {
  width: 100px;
  height: 25px;
  position: relative;
}

.dropdown-border {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--color-border-primary);
}

.as-helper-img {
  width: 400px;
  height: auto;
  border-style: solid;
  border-width: 3px;
  border-color: var(--color-border-primary);
  border-radius: 10px;
}

.theme-helper-img {
  width: 200px;
  height: auto;
  border-style: solid;
  border-width: 3px;
  border-color: var(--color-border-primary);
  border-radius: 10px;
}

.theme-helper-img-selected {
  width: 200px;
  height: auto;
  border-style: solid;
  border-width: 3px;
  border-color: var(--color-scale-blue-6);
  border-radius: 10px;
}

#context-switcher-menu {
  width: 300px;
}

.user-settings {
  width: 60%;
}

.project-settings {
  width: 60%;
}

#timeline-fix {
  display: flex;
}

#text-area {
  resize: none;
}

.signup-content-box {
  max-width: 600px;
}

.signup-info-box {
  height: 200px;
}

#signup-button {
  color: var(--color-text-primary);
  border-color: var(--color-border-primary);
  border-style: solid;
  border-width: 2px;
}

.user-dialog {
  border-width: 5px;
  border-color: red;
}

.separator {
  border-bottom: 3px solid white;
  width: 100%;
}

.failed-status {
  border-left: 3px solid var(--color-icon-danger);
}

.unstable-status {
  border-left: 3px solid var(--color-icon-warning);
}