#wrapper {
    width: 600px;
    margin: 20px auto;
  }
  
  .path-style {
    fill: GhostWhite;
    stroke: Gainsboro;
    stroke-width: 1;
  }

  .machine-text {
    color: --color-text-link;
  }

  .bounding-circle {
    color: blue;
    border-color: orange;
    border-width: 5px;
  }