/* make the customizations */
$theme-colors: (
    "info": pink,
    "danger": teal
);

// Override default variables before the import
$accordion-bg: var(--color-bg-canvas);
$accordion-color:var(--color-text-primary);
$accordion-border-color: var(--color-border-primary);
$accordion-border-width: 2px;

$accordion-button-bg: var(--color-bg-tertiary);
$accordion-button-color: var(--color-text-primary);

$accordion-button-active-bg: white; //var(--color-bg-backdrop);
$accordion-button-active-color: var(--color-text-secondary);

$link-decoration: none;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

